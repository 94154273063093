// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "s_sC d_bD";
export var storyRowWrapper = "s_hx d_hx d_bK";
export var storyLeftWrapper = "s_sD d_bz d_bP";
export var storyWrapperFull = "s_sF d_cD";
export var storyWrapperFullLeft = "s_pl d_cD d_bz d_bP d_bD";
export var contentWrapper = "s_mv d_hy";
export var storyLeftWrapperCenter = "s_sG d_hz d_bz d_bD d_bP d_bJ";
export var storyRightWrapperCenter = "s_sH d_hF";
export var storyHeader = "s_sJ d_hD d_w d_cs";
export var storyHeaderCenter = "s_hC d_hC d_w d_cs d_dw d_by";
export var storyParagraphCenter = "s_hB d_hB d_by d_dw";
export var storyBtnWrapper = "s_sK d_d2 d_d1 d_w d_bz d_bG";
export var storyBtnWrapperCenter = "s_hJ d_hJ d_d1 d_w d_bz d_bD";
export var imageWrapper = "s_qy d_fg d_Z";
export var imageWrapperFull = "s_sL d_w d_H d_bf d_Z";
export var SubtitleSmall = "s_qd q_qd q_qQ q_q1";
export var SubtitleNormal = "s_qf q_qf q_qQ q_q2";
export var SubtitleLarge = "s_qg q_qg q_qQ q_q3";
export var textLeft = "s_dv";
export var textCenter = "s_dw";
export var textRight = "s_dx";