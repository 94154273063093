// extracted by mini-css-extract-plugin
export var alignLeft = "t_qD d_fp d_bG d_dv";
export var alignCenter = "t_bP d_fq d_bD d_dw";
export var alignRight = "t_qF d_fr d_bH d_dx";
export var testimonialsContainer = "t_sM d_dW";
export var testimonialsContainerFull = "t_sN d_dT";
export var testimonialsMainHeader = "t_jw d_jw d_w d_cw";
export var testimonialsComponentParagraph = "t_jy d_jy";
export var testimonialsComponentSmall = "t_jx d_jx";
export var testimonialsComponentsWrapper = "t_js d_js d_y d_w d_cv d_b3 d_b5";
export var testimonialsComponentsWrapperDesign2 = "t_jt d_jt d_y d_w d_cv d_b3 d_b5 d_bC";
export var testimonialsComponentsWrapperDesign3 = "t_jv d_jv d_y d_w d_H d_b5 d_bT d_bz d_bJ d_bP d_bD";
export var testimonialsBackgroundColor = "t_sP d_d7";
export var colEqualHeight = "t_sQ d_bz";
export var testimonialsColumnWrapper = "t_jz d_jz d_b3";
export var testimonialsImageWrapper = "t_jp d_jp d_w d_Z";
export var testimonialsImageWrapperDesign2 = "t_jq d_jq d_r d_Z d_bx";
export var testimonialsImageWrapperDesign3 = "t_jr d_jr d_Z d_bz";
export var design2TextWrapper = "t_sR d_bC d_bP d_bJ";
export var design3 = "t_sS d_cD d_H";
export var imageDesign2 = "t_sT";
export var SmallSmall = "t_qz q_qz q_qQ q_rb";
export var SmallNormal = "t_qB q_qB q_qQ q_rc";
export var SmallLarge = "t_qC q_qC q_qQ q_q8";
export var exceptionWeight = "t_sV q_rg";